import React from 'react';
import Layout from '@common/Layout';
import NavbarExternal from '@common/NavbarExternal';
import Footer from '@sections/Footer';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { Container } from '@components/global';
import Img from 'gatsby-image';
import styled from 'styled-components';

const Title = styled.div`
  h1 {
    text-align: center;
    padding: 0px;
    margin-top: 0px;
    margin-bottom: 3rem;
    color: ${props => props.theme.color.dark_primary};
  }
`;

export const Ingredients = styled.div`
  li {
    color: ${props => props.theme.color.black};
  }

  h2 {
    color: ${props => props.theme.color.dark_primary};
  }

  margin-top: 50px;
  background-image: url("/img/crossword.png");
  background-color: ${props => props.theme.color.primary};

  hr {
    border-top: 2px dashed;
    color: ${props => props.theme.color.dark_primary};
  }

  ul {
    list-style-type: square;
    color: ${props => props.theme.color.dark_primary};
  }
`;

export const Farewell = styled.div`
  margin-top: 100px;
  margin-bottom: 100px;

  p {
    color: ${props => props.theme.color.grey};
  }

  a {
    color: ${props => props.theme.color.dark_primary};
    text-decoration: none
  }
`;


export const BottomImage = styled.div`
    max-height: 600px;
    max-width: 600px;
    margin:10px auto;
`;

export const HeaderImage = styled.div`
    max-height: 400px;
    max-width: 1000px;
    margin:150px auto;
`;


const IndexPage = ({ data }) => {
  return (
      <Layout>
      <NavbarExternal/>
      <Container>
        <HeaderImage><Img fluid={data.top.childImageSharp.fluid}/></HeaderImage>
        <Title><h1>Saffron Moroccan Chicken</h1></Title>
        <p>
          Truth be told, I try to eat a vegetarian diet.  In our early days on the farm I slaughtered literally
          thousands of chickens and turkeys, and decided I didn't want to be a part of that again.  That said, my
          sister-in-law made this dish one night and I slipped a bit on the diet.  The smell that filled the kitchen
          was wonderful, and although I limited myself to only a little bite of the meat itself, it's become a family
          favorite on the farm.
        </p>

        <Ingredients>
          <hr/>
          <center><h2>Ingredients</h2></center>
          <ul>
            <li>5-6 lbs bone-in chicken, cut into pieces <i>(we prefer thighs.)</i></li>
            <li>2 large sweet onions, coarsely chopped</li>
            <li>4 whole carrots, peeled</li>
            <li>&#188; cup fresh cilantro, chopped</li>
            <li>1 teaspoon saffron threads, crumbled <i>(Use this part of the saffron for the sauce.)</i></li>
            <li>1 teaspoon saffron threads, whole <i>(And this part for a decoration.)</i></li>
            <li>1 tablespoon ground ginger</li>
            <li>&#189; teaspoon black pepper</li>
            <li>1 teaspoon white pepper <i>(You can substitute pure black pepper, but most chefs believe white pepper harmonizes
            better with the floral taste of the saffron.  And let's be honest here, the saffron is the main part of the dish!)</i></li>
            <li>2 sticks cinnamon</li>
            <li>1 teaspoon turmeric <i>(Turmeric is another spice like saffron with natural healing properties)</i></li>
            <li>1 or 2 teaspoons salt</li>
            <li>&#188; cup butter</li>
            <li>&#188; cup olive oil</li>
          </ul>
          <hr/>
        </Ingredients>
        <Container>
          <Grid inverse>
            <div>
              <h2>Measure the spices</h2>
            </div>
            <Art><Img fluid={data.spices.childImageSharp.fluid}/></Art>
          </Grid>
        </Container>
        <Container>
          <Grid inverse>
            <div>
              <h2>Gather fresh ingredients</h2>
            </div>
            <Art><Img fluid={data.fresh.childImageSharp.fluid}/></Art>
          </Grid>
        </Container>
        <Container>
          <Grid inverse>
            <div>
              <h2>Chop</h2>
            </div>
            <Art><Img fluid={data.chop.childImageSharp.fluid}/></Art>
          </Grid>
        </Container>
        <Container>
          <Grid inverse>
            <div>
              <h2>Warm cookware</h2>
              <p>We're using a fun tangine dish, but any type of cast iron enamel cookware with at least 3 quarts (need room to stir) and a lid should work.</p>
            </div>
            <Art><Img fluid={data.warm.childImageSharp.fluid}/></Art>
          </Grid>
        </Container>
        <Container>
          <Grid inverse>
            <div>
              <h2>Cover and Cook</h2>
              <p>Over medium to low heat for about an hour and 15 minutes.  Stir occasionally.  Meat should easily fall off the bone.</p>
            </div>
            <Art><Img fluid={data.cook.childImageSharp.fluid}/></Art>
          </Grid>
        </Container>
        <Container>
          <Grid inverse>
            <div>
              <h2>Enjoy</h2>
              <p>Garnish chicken top with whole saffron threads while steaming.  Serve over rice.</p>
            </div>
            <Art><Img fluid={data.serve.childImageSharp.fluid}/></Art>
          </Grid>
        </Container>

        <Farewell>
          <p>
            If you enjoyed this recipe, please email <a href="fresh@oregonsaffron.com">fresh@oregonsaffron.com</a> to
            let us know.  I'll be sure to pass the compliments to my sister-in-law.
          </p>
        </Farewell>

        <BottomImage><Img fluid={data.bottom.childImageSharp.fluid}/></BottomImage>
      </Container>
      <Footer/>
      </Layout>
  )
}

const propTypes = {
  data: PropTypes.object.isRequired,
}


IndexPage.propTypes = propTypes

export default IndexPage

const Grid = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-gap: 40px;
  text-align: right;
  align-items: center;
  justify-items: center;
  margin: 24px 0;

  ${props =>
    props.inverse &&
    `
    text-align: left;
    grid-template-columns: 2fr 3fr;
  `}

   hr {
      border-top: 2px dashed #8D6E98;
   }

  h2 {
    margin-bottom: 10px;
    margin-top: 20px;
    color: ${props => props.theme.color.dark_primary};
  }

  p {
    color: ${props => props.theme.color.grey};
  }

  a {
    color: ${props => props.theme.color.dark_primary};
    text-decoration: none
  }

  &:last-child {
    margin-bottom: -1px;
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    text-align: left;
    margin-bottom: 96px;

    ${props =>
      props.inverse &&
      `
        ${Art} {
          order: 2;
        }
    `}
  }
`;

const Art = styled.figure`
  margin: 0;
  max-width: 500px;
  width: 100%;
`;


export const query = graphql`
  query {
    top: file(relativePath: { eq: "chicken/spice_mix_landscape.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    bottom: file(relativePath: { eq: "chicken/final_dish.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    spices: file(relativePath: { eq: "chicken/saffron_and_spices.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    fresh: file(relativePath: { eq: "chicken/fresh_ingredients.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    chop: file(relativePath: { eq: "chicken/chop_onion.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    warm: file(relativePath: { eq: "chicken/warm_dish.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    cook: file(relativePath: { eq: "chicken/cook.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    serve: file(relativePath: { eq: "chicken/serve_with_rice.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    },
  }
`;
